<template>
  <div>
    <h2>Вывоз отходов</h2>
    
      <div v-for="item in wastes" v-bind:key="item.ss01DictionaryId">
        <p  style="font-weight: bold;">{{item.wasteName}}</p>
        <p>Код отхода: <span  style="font-weight: bold;">{{item.wasteCode}}</span></p>
        
        <p>Дата(-ы): <span  style="font-weight: bold;">{{dataToString(item.data)}}</span></p>
        <p>Общее количество наличия данного вида отходов: <span  style="font-weight: bold;">{{item.amount_of_current_unit_all}}</span> {{item.agrName}}</p>
        <input type="checkbox" id="checkbox" v-model="checked">
        <label for="checkbox">Экспорт</label>
        <div v-for="itemorg in item.orgs" v-bind:key="itemorg.id">
          <div v-for="wastor in itemorg.wastorg" v-bind:key="wastor.tphw_id">
            <el-row>
            
              <el-col :span="10" style="background-color: #EEEEEE">
              <p v-if="wastor.orgName">{{wastor.orgName}}</p>
              <p v-if="!wastor.orgName">{{itemorg.name}}</p>
              </el-col>

              <el-col align="middle" :span="5" style="background-color: #EEEEEE; height:68px; vertical-align:middle; display:table-cell;">
              Кол-во отходов (хранится) <br>  
              {{wastor.amount_of_current_unit}} {{wastor.pss93name}}
              </el-col>
              <el-col :span="6" style="margin-left:20px">
                Внесите количество отхода, вывозимое за 1 день
                <el-input margin-left="20 px" v-model="wastor.out" placeholder="Наименование" 
                v-bind:disabled="tphwInclude.find(el=>el==parseInt(wastor.tphw_id))!=undefined ||wastor.amount_of_current_unit<=0" style="font-weight: bold;border-color:blue" @change="alert(wastor)"></el-input>
                <div v-if="wastor.out == null ? false : (Number(wastor.out.replace(',','.'))*item.data.length)>Number(wastor.amount_of_current_unit)" style="color: red; font-size:10px">Ошибка! Превышение доступного количества отхода</div>
              </el-col>
            </el-row>
            <el-row v-if="checked == true">
              <el-col :span="3">
                <div style="height:40px; vertical-align:middle; display:table-cell;">
                По Экспорту:
                </div>
              </el-col>
              <el-col :span="3">
                <el-input v-model="wastor.exportstore"></el-input>
              </el-col>
              <el-col :span="3">
                <div style="height:40px; vertical-align:middle; display:table-cell;">
                 {{wastor.pss93name}}
                 </div>
              </el-col>
            </el-row>
            <br>
          </div>
        </div>
        <br>
      </div>
      
      <el-button type="primary" @click="showData" 
      >Продолжить</el-button>
  </div>
  
</template>
<script>
//import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
//import AccompanyingPassport from "@/components/ecologist/disposal/accompanyingPassport";
export default {
    //components: { AccompanyingPassport},
    data() {
    return {
      tphwInclude:[9562,9563,9565,9566,9584,9543,9544,9545,9546,9548,9549,9552,9553,9554,
9550,9556,9557,9559,9561,9558,9564,9555,9551,9547,9542,9560,9603,9604,
9586,9588,9590,9589,9592,10078,9593,9594,9595,9598,9599,9600,9601,9602,
9591,9596,9597],
      checked: false,
      date: null,
      resp:[],
      wastes:[
        /*{
          wasteName:"Проба",
          data:null,
          orgs:[
            {
              id:null,
              name:null,
              wastorg:
              [
                {
                  amount_of_current_unit:"11111",
                  orgName:"11111",
                  organizationId:"11111",
                  out:"11111",
                }
              ]
            }
          ],
        }*/
      ],
      chekbranches: [],
      defaultProps: {
        title: "wasteName",
      },
      defaultwastorg:[
        {
          //orgName:"",
          amount_of_current_unit:"0",
          organizationId:0,
        },
      ],
    };
  },

  name: "disposalSelectedWasteList",
  methods: {
    /*openAddWasteModal() {
      this.$store.dispatch("setShowModal", "AccompanyingPassport");
    },*/
    dataToString(datas)
    {
      let str="";
      datas.forEach(data => {
        str=str+" "+data.split('-')[2]+"."+data.split('-')[1]+"."+data.split('-')[0]+","
      });
      return str.slice(0,str.length-1);
    },
    alert(wastor)
    {
      console.log(wastor.out+' '+wastor.amount_of_current_unit);
    },
    async getUnitList(unitType, unitId) {
      return await this.$store.dispatch("organizationUnitsForSelect", {
        unitType: unitType,
        unitId: unitId
      });
    },
    async getDataWasteTechProcessOrg(ss01Id, date, orgId) {
      return await this.$store.dispatch("getDataWasteTechProcessOrg", {
        ss01Id: ss01Id,
        date: date,
        orgId: orgId
      });
    },

     showData() {

      let wast = [];
      this.wastes.forEach(item => {
        if(item.amount_of_current_unit_all!=0)
        wast.push(item);
      });
      /*let rul=0;
      this.wastes.forEach(item => {
        item.orgs.forEach(itemorg => {
          itemorg.wastorg.forEach(wastor => {
            if(wastor.out.replace(',','.')>wastor.amount_of_current_unit)
            console.log("rul=?" + 1);
          });
        });
      });*/
      this.$router.push({ path: "accompanying-passport", query: {wastes: wast} });
    },

    

    async submitForm(data)
    {
        return await this.$store.dispatch("receiveDataEntryFormWasteDecline", {
        data: data
      });
    },

  },

  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
    }),
  },
  async mounted() {
    this.wastes=[];
    this.date=this.$route.query.date;
    //let dat=(new Date(this.date)).toLocaleDateString();
    if(this.$route.query.wastes instanceof Array)
    this.$route.query.wastes.forEach((waste)=>{
    this.wastes.push(waste);
    })
    else 
    this.wastes.push(this.$route.query.wastes);

    
    this.chekbranches=this.$route.query.chekbranches;



    this.wastes.forEach(async (waste, index_waste)=>{
      var all=0;

      waste.data = this.$route.query.date;;
      console.log("waste:",waste);
      let dt = "" + (new Date(this.date[this.date.length-1]).getDate()<10 ? ("0" + new Date(this.date[this.date.length-1]).getDate()) : new Date(this.date[this.date.length-1]).getDate()) + "." + (new Date(this.date[this.date.length-1]).getMonth()<9 ? ("0" + (new Date(this.date[this.date.length-1]).getMonth() +1)) : (new Date(this.date[this.date.length-1]).getMonth() +1)) + "." + new Date(this.date[this.date.length-1]).getFullYear();
      //waste.data=(new Date(this.date)).toLocaleDateString();
      waste.amount_of_current_unit_all=0;

      waste.orgs.forEach(async (org, index_org)=>{
        if((this.chekbranches.find(chekbr => chekbr.id == org.id)||this.chekbranches.find(chekbr => chekbr.id == 1))&&!(org.id==null))
        {
         await  this.getDataWasteTechProcessOrg(waste.ss01DictionaryId, dt, org.id).then(async (response) => {
          if(this.tphwInclude.find(el=>el==parseInt(response.data.length == 0 ? "0" :response.data[0].tphw_id))==undefined)
         {
          this.$set(this.wastes[index_waste],'agrName',(response.data.length == 0 ? null :response.data[0].pss93name));
          this.$set(this.wastes[index_waste].orgs[index_org],'wastorg',(response.data.length == 0 ? this.defaultwastorg :response.data));
          response.data.length == 0 ? all=all+0 : all = all+parseFloat(response.data[0].amount_of_current_unit);
          this.$set(this.wastes[index_waste].orgs[index_org].wastorg[0],'out',((response.data.length == 0)  ? "0": (response.data[0].amount_of_current_unit/this.wastes[index_waste].data.length).toString()));
          this.$set(this.wastes[index_waste].orgs[index_org].wastorg[0],'exportstore', 0);
          //if (response.data.length == 0) this.wastes[index_waste].orgs[index_org].wastorg[0].orgName=index_org;
          this.$set(this.wastes[index_waste],'amount_of_current_unit_all',Math.round(all*100000)/100000);
         }
        })}
      
       // else
       // this.wastes[index_waste].org[index_org]=undefined;
        
      })
      
      
    })
    console.log("this.wastes");
    console.log(this.wastes);
    console.log("this.wastes");
  },
};
 
</script>

<style scoped>

</style>