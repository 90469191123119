<template>
    <div>
      <limits-reserch1></limits-reserch1>
    </div>
  </template>
  
  <script>
  import LimitsReserch1 from "@/components/ecologist/limitsReserch/limitsReserch1";
  export default {
    name: "LimitsReserch1View",
    components: { LimitsReserch1 },
  };
  </script>
  
  <style scoped></style>
  