import { render, staticRenderFns } from "./disposalSelectedWasteList.vue?vue&type=template&id=7a2a0b57&scoped=true&"
import script from "./disposalSelectedWasteList.vue?vue&type=script&lang=js&"
export * from "./disposalSelectedWasteList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2a0b57",
  null
  
)

export default component.exports