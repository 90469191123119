<template>
    <div>
      <h2>Сопроводительные паспорта перевозки отходов</h2>
      <el-form
        ref="formedForm"
        label-position="top"
        label-width="120px"
      >
      <el-select
      clearable
          class="searchPass"
          v-model="passport.num"
          filterable
          placeholder="Номер паспорта"
          value-key="id"
        >
          <el-option
            v-for="item in passports"
            :key="item.id"
            :label="'Номер: '+item.number+' Дата:' + item.date"
            :value="item.id"
          >
          </el-option>

      </el-select>
      <el-row>
        <el-col :span="4">
          <el-form-item label="Тип файла">
            <el-select
              clearable
              v-model="passport.fileType"
              placeholder="Формат отчета"
            >
              <el-option
                v-for="item in fileTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <div style="padding-bottom: 10px; height: 40px;">
            <br>
          </div>
          <el-button @click="downloadReport" type="primary" style="margin-left: 10px;"
            >Выгрузить паспорт</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">Сортировать по:</el-col>
        <el-col :span="4">
          <el-select
          @change="sort"
          v-model="varSor"
            class="m-2"
            placeholder="Сортировка"
            size="large"
            style="width: 240px"
          >
            <el-option
              v-for="sortv in sortVar"
              :key="sortv.id"
              :label="sortv.name"
              :value="sortv.id"
            />
          </el-select>
        </el-col>
        <el-col :span="2">За год:</el-col>
        <el-col :span="4">
        <el-input v-model="serYear" clearable @change="sortyear(serYear)" placeholder="Укажите год"></el-input>
        </el-col>
      </el-row>

        <el-table stripe :data="passports" :span="12">
          <el-table-column label="Сопроводительный паспорт" prop="" :span="4" >
            <template slot-scope="item">
              {{'Номер: ' + item.row.number }}
            </template>
          </el-table-column>
          <el-table-column label="Объекты" prop="" :span="4">
            <template slot-scope="item">
              {{'Объекты: ' +  strOrg(item.row)}}
            </template>
          </el-table-column>
          <el-table-column label="Дата" prop="" :span="4" >
            <template slot-scope="item">
              {{ item.row.date }}
            </template>
          </el-table-column>
          <el-table-column label="" prop="" :span="4">
            <template slot-scope="item">
                <el-button @click="downloadReport1(item.row.id)" type="primary" style="margin-left: 10px;"
                >Выгрузить паспорт</el-button>
                <el-button @click="editReport(item.row.id)" type="warning" style="margin-left: 10px;"
                >Редактировать паспорт</el-button>
                <el-button @click="deleteReport(item.row.id)" type="danger" style="margin-left: 10px;border-bottom: 1px;"
                >Удалить</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <custom-modal v-if="showModal === 'dataPasport'"
      :visible="showModal === 'dataPasport'">
        <disposal-pasport-form
          :action="passport"
        ></disposal-pasport-form>
      </custom-modal>
    </div>
  </template>
  
  <script>
  //import SearchBlock from "@/components/searchBlock";
  import CustomModal from "@/components/customModal";
  import { AXIOS } from "@/AXIOS/http-common";
  import disposalPasportForm from "@/components/ecologist/reports/passport/disposalPasport";
  import { mapGetters } from "vuex";
  export default {
    name: "PassportReport",
    components: { disposalPasportForm, CustomModal },
    //components: { SearchBlock },
    data() {
      return {
        serYear:'',
        sortVar:[
          {id:1,name:"Номеру возрастанию"},
          {id:2,name:"Номеру убыванию"},
          {id:3,name:"Дате возрастанию"},
          {id:4,name:"Дате убыванию"},
        ],
        varSor:null,
        passports:null,
        passportsALL:null,
        passport: {
          fileType: ".pdf",
          num:null,
        },
        fileTypes: [
          {
            value: ".pdf",
            label: ".pdf",
          },
          {
            value: ".xls",
            label: ".xls",
          },
        ],
      };
    },

    computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
    }),
  },
  
    methods: {
    sortyear(year)
    {
      if(year!='') 
      this.passports=this.passportsALL.filter((pass) =>  parseInt(pass.date.slice(-4)) === parseInt(year));
      else 
      this.passports=this.passportsALL;
    },
        
    sort()
    {
      switch (this.varSor){
      case 1:
      this.passports=this.passports.sort((a, b) => a.number - b.number);break;
      case 2:
      this.passports=this.passports.sort((a, b) => b.number - a.number);break;
      case 3:
      this.passports=this.passports.sort((a, b) => new Date(a.date.split('.')[2],a.date.split('.')[1]-1,a.date.split('.')[0]) - new Date(b.date.split('.')[2],b.date.split('.')[1]-1,b.date.split('.')[0]));break;
      case 4:
      this.passports=this.passports.sort((a, b) => new Date(b.date.split('.')[2],b.date.split('.')[1]-1,b.date.split('.')[0]) - new Date(a.date.split('.')[2],a.date.split('.')[1]-1,a.date.split('.')[0]));break;
      }
      
    },

    async  getNumbersPassport(){
            return await AXIOS.get("report/pasportNumb/").then(
        (response) => {
          this.passports=response.data;
          this.passportsALL=response.data;
        }
        );
    },

    downloadReport() {
      console.log(this);
      this.$store.dispatch("downloadPassport", this.passport);
    },

    downloadReport1(passport) {
      this.passport.num=passport;
      this.$store.dispatch("downloadPassport", this.passport);
    },

    editReport(passport)
    {
      this.passport.num=passport;
      console.log(this.passport.num);
      this.$store.dispatch("setShowModal", "dataPasport");
    },

    addPasportToTable(item)
    {
      this.passport=item;//.action;
      //this.wastes[0]=item.wastes;
    },

    strOrg(row)
    {
      let S='';
      row.orgsList.forEach(element => { S=S+element+'/'
      });
      return S;
    },


    async deleteReport(id)
    {
      this.confirm(
        "Удалить данные?",
        "Вы уверены что хотите удалить данные",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteReport", id)
          .then(() => {
            this.notification("Успешно", "Данные удалены", "success");
            this.getNumbersPassport();
          });
      });
    }
      
    },
  
  
    mounted() {
        this.getNumbersPassport();
    },
  };
  </script>
  
  <style scoped></style>
  