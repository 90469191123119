<template>
    <div>
      <limits-reserch2></limits-reserch2>
    </div>
  </template>
  
  <script>
  import LimitsReserch2 from "@/components/ecologist/limitsReserch/limitsReserch2";
  export default {
    name: "LimitsReserch2View",
    components: { LimitsReserch2 },
  };
  </script>
  
  <style scoped></style>
  