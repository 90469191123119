<template>
    <div>
      <limits-reserch3></limits-reserch3>
    </div>
  </template>
  
  <script>
  import LimitsReserch3 from "@/components/ecologist/limitsReserch/limitsReserch3";
  export default {
    name: "LimitsReserch3View",
    components: { LimitsReserch3 },
  };
  </script>
  
  <style scoped></style>
  